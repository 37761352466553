$(document).ready(function(){
	$('.messageRecipientSwitcher .tab a').click(function(){
		var tabClass = $(this).attr('href');
		var tabClass = tabClass.substring(1);
        $('.messageRecipientSwitcher .tab a').removeClass('active');
        $(this).addClass('active');
		$(this).parent().parent().parent().siblings('.formContainer').removeClass().addClass('c__section--content no__border formContainer' + ' ' + tabClass);
	});



    var totalHeight = 0;

    $(".activityFilterList").children('.collectionItem').each(function(){
        totalHeight = totalHeight + $(this).outerHeight(true) - 50;
    });
    if($('.activityListLine').length){
        $('.activityListLine').height(totalHeight - 70);
    }

    $('.messageSearchTrigger').click(function(e){
        e.preventDefault();
        $('.messageBox.active').toggleClass('searchBoxActive');
        $('.messageBox.active .c__search--box').toggleClass('active');
    });
    if (window.location.hash) {
        var hash = window.location.hash;
        var clientMessages = 'clientMessages';
        var whistleBlowerMessages = 'whistleBlowerMessages';
        if (hash === '#' + clientMessages || hash === '#' + 'clientView') {
            $('.messageBox.active').removeClass('searchBoxActive');
            $('.messageBox.active .searchBox').removeClass('active');
            $('.messageBox').removeClass('active');
            $('.messageBox').each(function(){
                if($(this).attr('id') == clientMessages){
                    $(this).addClass('active');
                    $('.' + whistleBlowerMessages).addClass(clientMessages).removeClass(whistleBlowerMessages);
                    var messageSwitcher = $('.messageSwitcher');
                    messageSwitcher.removeClass('active');
                    messageSwitcher.each(function() {
                        if ($(this).attr('href') == '#' + clientMessages) {
                            $(this).addClass('active');
                        }
                    });
                }
                if($(this).length > 0 && $(".lastMessageContainer").length) {
                    $(hash).animate({
                        scrollTop: $(".lastMessageContainer").position().top
                    }, 1000);
                }

            });
        }



    }

    $('.messageSwitcher').click(function(){
        $('.messageBox.active').removeClass('searchBoxActive');

        $('.messageBox.active .c__search--box').removeClass('active');

        $('.messageBox').removeClass('active');
        var triggerVal = $(this).attr('href');

        history.replaceState(null, '', triggerVal);

        triggerVal = triggerVal.substring(1);

        $('.messageBox').each(function(){
            if($(this).attr('id') == triggerVal){
                $(this).addClass('active');
            }
        });
        if($('#' + triggerVal).length > 0) {
            $('#' + triggerVal).animate({
                scrollTop: $(".lastMessageContainer").position().top
            }, 1000);
        }

    });

    $('#add-another-attachment').click(function(e) {
        e.preventDefault();
        var attachmentList = $('#attachment-fields-list');
        var labelWidget = '<label class="filetype filetype_' + attachmentCount + ' element" for="disclosure_message_attachmentCollection___name__">Add attachment</label>';
        var buttonRemoveWidget = '<span class="remove remove_' + attachmentCount + '"><em class="fa fa-trash-o"></em></span>';
        var buttonClearWidget = '<span class="clear clear_' + attachmentCount + '"><em class="fa fa-remove"></em></span>';
        // grab the prototype template
        var newWidget = attachmentList.attr('data-prototype');
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        newWidget = newWidget.replace(/__name__/g, attachmentCount);
        buttonRemoveWidget = buttonRemoveWidget.replace(/__name__/g, attachmentCount);
        buttonClearWidget = buttonClearWidget.replace(/__name__/g, attachmentCount);
        labelWidget = labelWidget.replace(/__name__/g, attachmentCount);
        var currentCount = attachmentCount;
        attachmentCount++;

        // create a new list element and add it to the list
        var newLi = $('<li></li>').html( newWidget + labelWidget + buttonRemoveWidget);
        newLi.appendTo(attachmentList);

        $('.remove_' + currentCount).on('click', function(e) {
            e.preventDefault();
            $(this).closest('li').remove();
        });
        $('#disclosure_message_attachmentCollection_' + currentCount).on('change', function(e) {
            var fileName = $(this)[0].files[0].name;
            $('.filetype_' + currentCount).text(fileName);
        });

        $('.clear_' + currentCount).on('click', function(e) {
            e.preventDefault();
            $(this).prev('.filetype').val('');
        });



    });
   
    

    $('.btnRemoveParentAttachment').click(function() {
        var elemToRemoveClass = jQuery(this).data("parentattachment");
        var element = $('.' + elemToRemoveClass);
        element.remove();

        return false;

    });




	var searchOptions = {
        valueNames: [ 'messageContent', 'userName', 'submissionTime', 'messageSubject' ]
	};

    if($('#whistleBlowerMessages').length > 0) {
        var messageList = new List('whistleBlowerMessages', searchOptions);

        messageList.on("updated", function() {

            $('#whistleBlowerMessages.active').unhighlight();

            var search = $("#whistleBlowerMessages .search").val();
            var words = search.split(" ");
            $('#whistleBlowerMessages.active').highlight(words);

        }); 
    }
    if($('#clientMessages').length > 0) {
        

        var clientList = new List('clientMessages', searchOptions);

        

        clientList.on("updated", function() {
            
            $("#clientMessages.active").unhighlight();
            
            var search = $("#clientMessages .search").val();
            var words = search.split(" ");
            
            $("#clientMessages.active").highlight(words);
            

        }); // trigger
    }

    if((window.location.hash === '#whistleBlowerMessages' || !window.location.hash)  && $('.messageBox').length > 0) {
        $('.messageBox').animate({
            scrollTop: $('.singleMessageContainer:last-of-type').offset().top
        }, 1000);
    }
    




});



