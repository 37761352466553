
function sendLoginCode(submittedForm, resend)
{
    // noinspection ES6ConvertVarToLetConst
    var otpCode = $('otp_code');
    if (otpCode.val() === '' || otpCode.val() === null) {
        otpCode.prop('disabled', true);
    }

    // noinspection ES6ConvertVarToLetConst
    var formData = $('.authy').serializeArray();

    if (typeof resend !== 'undefined' && resend === true) {
        otpCode.prop('disabled', true);
        formData.push({name: "_resend", value: true});
    }

    $.ajax({
        url: '{{AUTH_PATH}}',
        type: "POST",
        data: formData,
        success: function (response) {
            if (response.data.cellphone) {
                $('.cellphone').text(response.data.cellphone);
            }
            if (response.data.success === true) {
                $('#otp_code_container').removeClass('hide');
            }

            $("#otp_error")
                .fadeOut()
                .addClass('hide')
                .text('');
            $("#otp_resend").addClass('hide');

            if (typeof response.data.valid && response.data.valid === true) {
                $('.authy').submit();
            }
        },
        error: function (xhr) {
            if (xhr.responseJSON) {
                $("#otp_error")
                    .fadeIn()
                    .removeClass('hide')
                    .text(xhr.responseJSON.data.message);

                $("#otp_resend").removeClass('hide');
            }
        },
        always: function () {
            otpCode.prop('disabled', false);
            $('#otp_code_container').removeClass('hide');
        }
    });
}

$(function () {
    $("#otp_continue").on("click", function (e) {
        e.preventDefault();
        sendLoginCode(this);
    });

    $("#otp_resend").on("click", function (e) {
        e.preventDefault();
        sendLoginCode(this, true);
    });
});
