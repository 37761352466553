$(function () {
    $(document).ready(function(){
        const questionnaireSubmitButton = $('input[type="submit"].formSubmit');
        const questionnaireSubmitButtonValue = questionnaireSubmitButton.val();

        var inputs = document.querySelectorAll( '.fileUpload' );
        Array.prototype.forEach.call( inputs, function( input )
        {
            var label	 = document.getElementsByClassName('fileUploadContent'),
                labelVal = label.innerHTML;

            input.addEventListener( 'change', function( e )
            {
                var fileName = '';
                if( this.files && this.files.length > 1 ) {
                    fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                }
                else {
                    fileName = e.target.value.split( '\\' ).pop();
                }

                if( fileName ) {
                    $(label).html(fileName);
                }
                else {
                    label.innerHTML = labelVal;
                }

            });
        });

        $('#log_out_wb').on('click', function (e) {
            e.preventDefault();

            const confirmationUrl =  $('#log_out_wb').attr('href');
            const confirmationModal = $('#logoutConfirmationModal');
            let confirmationText = 'Are you sure?';

            confirmationModal.addClass('active').show();

            if ($(this).data('confirmation')) {
                confirmationText = $(this).data('confirmation');
                confirmationModal.find('.dashCardTitle').html(confirmationText);
            }

            $('.confirm-no').on('click', function () {
                confirmationModal.removeClass('active').hide();
            });

            $('.confirm-yes').on('click', function () {
                confirmationModal.removeClass('active').hide();
                window.location = confirmationUrl;
            });
        });



        if($('body').hasClass('disclosureForm')) {
            $('.progressBar').each(function(){
                let progBarLength = $(this).children('li').length;
                progBarLength = 100/progBarLength;
                $(this).children('li').css('width', progBarLength + '%');
            });





            $('input.showIdentityForm + label').click(function(){
                $('html, body').animate({
                    scrollTop: $('.fieldIdentity .hidden-anchor').offset().top
                }, 1000);
            });

            $('.readMoreContainer').each(function(){
                if($(this).children('.readMoreTextContainer').children('.readMoreText').children('p').length > 1) {
                    const initHeight = $(this).find('p:first-of-type').outerHeight();
                    $(this).find('.readMoreTextContainer').first().scrollTop(0);
                    $(this).children('.readMoreTextContainer').css('max-height', initHeight);
                    $(this).children('.readMoreTextContainer').css('overflow-y', 'hidden');
                } else {
                    $(this).addClass('no-p-b');
                    $(this).find('a.readMoreTrigger').hide();
                }
            });

            $('a.readMoreTrigger').click(function(e){
                e.preventDefault();
                const initHeight = $(this).parent('.readMoreContainer').children('.readMoreTextContainer').find('p:first-of-type').outerHeight();
                if($(this).hasClass('active')) {
                    $(this).text('Read more');
                    $(this).parent('.readMoreContainer').find('.readMoreTextContainer').first().scrollTop(0);
                    $(this).parent('.readMoreContainer').children('.readMoreTextContainer').css('max-height', initHeight);
                    $(this).parent('.readMoreContainer').children('.readMoreTextContainer').css('overflow-y', 'hidden');
                    $(this).removeClass('active');
                    $(this).parent().removeClass('active');
                } else {
                    $(this).parent().addClass('active');
                    $(this).addClass('active');
                    $(this).text('Read less');
                    var winHeight = $(window).height();
                    var textHeight = $(this).parent('.readMoreContainer').find('.readMoreText').outerHeight();
                    const maxHeight = Math.min(textHeight , winHeight/2);
                    $(this).parent('.readMoreContainer').children('.readMoreTextContainer').css('max-height', maxHeight);

                    if (textHeight > maxHeight + 20) {
                        $(this).parent('.readMoreContainer').children('.readMoreTextContainer').css('overflow-y', 'auto');
                    }
                }

            });

            if($('.inputElem:checked').length > 0) {
                $('fieldset .fieldQuestions .inputElem:checked').each(function() {
                    const disclosureIdentityContainer = $('.disclosure_identity_container');
                    const disclosureIdentityContainerInput = $('.disclosure_identity_container input');
                    const showIdentity = $(this).hasClass('showIdentityForm');
                    const isIdentityForYourCall = $(this).data('behaviourvalue') === 'identityForYourCall';

                    $(this).trigger('click');
                    $(this).trigger('click');
                    if(showIdentity && isIdentityForYourCall || showIdentity && $(this).data('behaviourvalue') === 'identityForOrganization') {
                        disclosureIdentityContainer.removeAttr('hidden');
                    } else if($(this).hasClass('hideIdentityForm') && isIdentityForYourCall || !showIdentity && isIdentityForYourCall ) {
                        disclosureIdentityContainer.attr('hidden', 'hidden');
                        disclosureIdentityContainerInput.attr('novalidate', 'novalidate');
                    }
                });
            }

            let $sections = $('fieldset.card-panel');

            $sections.each(function(index, section) {
                $(section).find(':input').attr('data-parsley-group', 'block-' + index);
            });

            //jQuery time
            let current_fs, next_fs, previous_fs; //fieldsets
            let left, opacity, scale; //fieldset properties which we will animate
            let animating; //flag to prevent quick multi-click glitches

            $(".next").click(function(e){

                e.preventDefault();

                //Ajax POST to save each question answer --- Starts Here ----
                const questionnaireForm = $('form');
                const post_url = questionnaireForm.attr('action'); //get form action url
                const request_method = questionnaireForm.attr("method"); //get form GET/POST method
                const form_data = questionnaireForm.serialize(); //Encode form elements for submission

                $.ajax({
                    url : post_url,
                    type: request_method,
                    data : form_data,
                    beforeSend: function () {
                        questionnaireSubmitButton.prop('disabled', true);
                        questionnaireSubmitButton.val('Saving answers, please wait');
                    }
                });
                // --- Ends Here ----

                var $sections = $('fieldset.card-panel');

                if ($('form.disclosureStep').parsley().validate({group: 'block-' + curIndex()})) {

                    if(animating) return false;
                    animating = true;

                    current_fs = $(this).parent().parent().parent().parent().parent().not('.disabled');
                    next_fs = $(this).closest('.card-panel').nextAll('.card-panel').not('.disabled').first();

                    //activate next step on progressbar using the index of next_fs
                    $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

                    //show the next fieldset
                    next_fs.show();
                    //hide the current fieldset with style
                    current_fs.children('.c__formprogression--hook').animate({opacity: 0}, {
                        step: function(now, mx) {
                            //as the opacity of current_fs reduces to 0 - stored in "now"
                            //1. scale current_fs down to 80%

                            scale = 1 - (1 - now) * 0.2;
                            //2. bring next_fs from the right(50%)
                            left = (now * 50)+"%";
                            //3. increase opacity of next_fs to 1 as it moves in
                            opacity = 1 - now;
                            current_fs.children('.c__formprogression--hook').css({'transform': 'scale('+scale+')'});
                            next_fs.children('.c__formprogression--hook').css({'top': left, 'opacity': opacity});
                            next_fs.addClass('current');
                            next_fs.removeClass('hide');
                            window.scrollTo(0,0);
                            $('.readMoreContainer').each(function(){
                                if($(this).children('.readMoreTextContainer').children('.readMoreText').children('p').length > 1) {
                                    const initHeight = $(this).find('p:first-of-type').outerHeight();
                                    $(this).children('.readMoreTextContainer').css('max-height', initHeight);
                                } else {
                                    $(this).addClass('no-p-b');
                                    $(this).find('a.readMoreTrigger').hide();
                                }
                            });
                        },
                        duration: 800,
                        complete: function(){

                            current_fs.hide();
                            current_fs.removeClass('current');
                            animating = false;

                        },
                        //this comes from the custom easing plugin
                        easing: 'swing'
                    }).animate({ scrollTop: 0}, 'slow');
                }

                function curIndex() {
                    return $sections.index($sections.filter('.current'));
                }
            });

            $(".previous:not(.disabled)").click(function(e){
                e.preventDefault();
                if(animating) return false;
                animating = true;

                current_fs = $(this).parent().parent().parent().parent().parent();
                previous_fs = $(this).closest('.card-panel').prevAll('.card-panel').not('.disabled').first();

                //de-activate current step on progressbar
                $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

                //show the previous fieldset
                previous_fs.show();
                //hide the current fieldset with style
                current_fs.children('.c__formprogression--hook').animate({opacity: 0}, {
                    step: function(now, mx) {
                        //as the opacity of current_fs reduces to 0 - stored in "now"
                        //1. scale previous_fs from 80% to 100%
                        scale = 0.8 + (1 - now) * 0.2;
                        //2. take current_fs to the right(50%) - from 0%
                        left = ((1-now) * 50)+"%";
                        //3. increase opacity of previous_fs to 1 as it moves in
                        opacity = 1 - now;
                        current_fs.children('.c__formprogression--hook').css({'top': left});
                        previous_fs.children('.c__formprogression--hook').css({'transform': 'scale('+scale+')', 'opacity': opacity});
                        previous_fs.addClass('current');
                        previous_fs.removeClass('hide');

                    },
                    duration: 800,
                    complete: function(){
                        current_fs.hide();
                        current_fs.removeClass('current');
                        animating = false;
                    },
                    //this comes from the custom easing plugin
                    easing: 'swing'
                });
            });

            $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");


            function checkEnter(e){
                e = e || event;
                var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
                return txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13;
            }
            document.querySelector('form').onkeypress = checkEnter;

        }

        $(document).ajaxStop(function(){
            questionnaireSubmitButton.val(questionnaireSubmitButtonValue);
            questionnaireSubmitButton.prop('disabled', false);
        });
    });
});
