$.fn.equalHeight = function() {
    var maxHeight = 0;
    return this.each(function(index, box) {
        var boxHeight = $(box).height();
        maxHeight = Math.max(maxHeight, boxHeight);
    }).height(maxHeight);
};

$(document).ready(function() {
    if($(window).width() > 600) {
        $('.equalHeight').equalHeight();  
        $('.equal-height-searchField').equalHeight();        
    } else if($(window).width() <= 600) {
        $('.equalHeight').css('height','auto');        
        $('.equal-height-searchField').css('height','auto');
    }
});

$(window).resize(function(){
    $('.equalHeight').css('height','auto');
    $('.equalHeight').equalHeight();
    $('.equal-height-searchField').css('height','auto');
    $('.equal-height-searchField').equalHeight();        
    if($(window).width() <= 600) {
        $('.equalHeight').css('height','auto');
        $('.equal-height-searchField').css('height','auto');
    }
});