$(document).ready(function(){
    let disclose_identityForYourCall_Yes = $('.identityForYourCall.showIdentityForm');
    let disclose_identityForOrganisation_Yes = $('.identityForOrganization.showIdentityForm');

    $('.tooltipped').tooltip({delay: 50});

    if (true === disclose_identityForOrganisation_Yes.prop('checked'))
    {
        disclose_identityForYourCall_Yes.attr('checked', 'checked');
        $('#identity_question_form').removeAttr('hidden');
    }

    $('.identityForOrganization').click(function () {

        if (true === disclose_identityForOrganisation_Yes.prop('checked'))
        {
            disclose_identityForYourCall_Yes.attr('checked', 'checked');
            $('#identity_question_form').removeAttr('hidden');
        }

    });

    setIdentityFormState();
    $('.identityForYourCall').click(function () {
        setIdentityFormState();
    });

    $('input[type="radio"]').each(function(){
        if($(this).prop('checked') === true) {
            $(this).trigger('click');
        }

    });

    const none_of_the_above = $('#none_of_the_above_employee_list');
    if (none_of_the_above.length > 0) {
        const question_id_class = '.question_' + none_of_the_above.attr('name').split('][')[0].split('[')[1];
        const conduct_type_checkboxes = $('input[type="checkbox"]' + question_id_class);

        none_of_the_above.click(function () {

            if($(this).prop('checked') === true) {
                conduct_type_checkboxes.not('#none_of_the_above_employee_list').each(function () {
                    $(this).prop('checked', false);
                });
            }
        });

        conduct_type_checkboxes.not('#none_of_the_above_employee_list').each(function () {
            $(this).click(function () {
                none_of_the_above.prop('checked', false);
            });
        });
    }
});


function setIdentityFormState(){
    let disclose_identityForOrg_Yes = $('.identityForOrganization.showIdentityForm');
    let disclose_identityForOrg_No = $('.identityForOrganization:not(.showIdentityForm)');
    let disclose_identityForYourCall_Yes = $('.identityForYourCall.showIdentityForm');
    let disclose_identityForYourCall_No = $('.identityForYourCall:not(.showIdentityForm)');

    if (disclose_identityForYourCall_Yes.prop('checked'))
    {
        $('[data-parsley-required-if]').each(function(){
            $(this).attr('data-parsley-validate-if-empty','true');
        });
        $('#identityForOrganizationShowIdentityForm').remove();
        disclose_identityForOrg_Yes.removeAttr('disabled');
        disclose_identityForOrg_Yes.attr('checked', 'checked');
        $('#identity_question_form').removeAttr('hidden');

    } else if (disclose_identityForYourCall_No.prop('checked')){
        $('#identity_question_form').attr('hidden', 'hidden');

        $('[data-parsley-required-if]').each(function(){
            $(this).removeAttr('data-parsley-validate-if-empty');
        });

        disclose_identityForOrg_Yes.attr('disabled', 'disabled');
        disclose_identityForOrg_Yes.removeAttr('checked');
        disclose_identityForOrg_No.attr('checked', 'checked');
        disclose_identityForOrg_Yes.closest('.radio').after('<ul class="parsley-notices-list filled" id="identityForOrganizationShowIdentityForm"><li class="parsley-required">Yes has been disabled</li></ul>');
    }

}
function doEnable (idValue) {
    var obj=$('#'+idValue);
    obj.prop('hidden', false);
}
function doDisable (idValue) {
    var obj=$('#'+idValue);
    obj.prop('hidden', 'hidden');
}


//JS Functionality to display/hide specific textboxes triggered on radio button onchange event
$('input[data-behaviour="text"]').each(function (i, elem) {
   let question_id = elem.id.split('_')[1];
   let question_text = $('#question_' + question_id + '_text');
   let question_hide_radio = $('.question_' + question_id + '[data-behaviour="none"]');

   $(elem).change(function (event) {
       if($(event.target).is(':checked')) {
           question_text.removeAttr('hidden');
           question_text.prop('required',true);
       }
   });
   question_hide_radio.change(function (event) {
       if($(event.target).is(':checked')) {
           question_text.val('');
           question_text.prop('required',false);
           question_text.attr('hidden', 'hidden');
       }
   });
});

//Step 3
let requiredWitnessFields;
$('.witnessType').on('click', function () {
    const behaviorValue =  $(this).data('behaviourvalue');
    const witness_container = $('.witness_form_container');
    let witnesses = $('.witnessContainer');

    if (behaviorValue === 'hideWitnessType') {
        witness_container.prop('hidden', true);
        // remove required prop from witness forms to prevent parsley validating required fields
        requiredWitnessFields = $('.witnessContainer :input[required="required"]');
        setWitnessFieldsRequired(requiredWitnessFields, false);
    } else {
        if (witnesses.length === 0) {
            $('.add_user_link').trigger('click');
        }
        witness_container.prop('hidden', false);
        setWitnessFieldsRequired(requiredWitnessFields, true);
    }
})

$(':radio').on('click', function () {

    var behaviorType =  $(this).data('behaviour');
    var behaviorValue =  $(this).data('behaviourvalue');
    if (behaviorType == "disable_question") {
        getQuestionElements(behaviorValue.toString(), ",", "disable");
    }
    if (behaviorType == "show_question") {
        getQuestionElements(behaviorValue.toString(), ",", "enabled");
    }


});

function getQuestionElements(stringToSplit, separator, action) {

    var behaviorValueCollection = [stringToSplit];
    if (stringToSplit.indexOf(',') > -1) {
        behaviorValueCollection = stringToSplit.split(separator);
    }


    // Use for loop as there is no way to break forEach loop
    behaviorValueCollection.forEach(function (questionID) {
        var question = $('.question_'+questionID);
        if (question.length === 0) {
            console.log('Question not found in document');
        }
        else {
            // Disable Questions
            if (action === 'disable') {
                // Resetting if disabled.
                question.prop('value','');
                question.prop('checked', false);
                question.prop('disabled', true);
                question.removeAttr('required');
            }
            else
                question.prop('disabled', false);

        }

    });

    $('fieldset').each(function(){
        $(this).removeClass('disabled');

    });

    $('fieldset .fieldQuestions .inputElem[disabled]').each(function() {
        $(this).closest('.card-panel').addClass('disabled');
        $(this).removeAttr('required');
    });


}

// Rename or update this to make it more generic
$(document).ready(function(){
    const $addTagLinkQuestions = $('<a href="#" class="add_user_link btn waves-effect waves-brand white-text right">' +
        'Add Witness <i class="material-icons right">supervisor_account</i></a>');
    const $newLinkLiQuestions = $('<li></li>').append($addTagLinkQuestions);

    const $collectionHolderQuestions = $('ul.witness_form_template');
    $collectionHolderQuestions.find('li').each(function() {
        addQuestionsFormDeleteLink($(this));
    });
    $collectionHolderQuestions.append($newLinkLiQuestions);

    $collectionHolderQuestions.data('index', $collectionHolderQuestions.find(':input').length);
    $addTagLinkQuestions.on('click', function(e) {
        e.preventDefault();
        addQuestionsForm($collectionHolderQuestions, $newLinkLiQuestions);
    });
});

function addQuestionsForm($collectionHolder, $newLinkLi) {
    const prototype = $collectionHolder.data('prototype');
    const witnessFormContainer = $('.witness_form_container');
    const index = witnessFormContainer.data('index') || witnessFormContainer.length;
    const newForm = prototype.replace(/__name__/g, index);
    /* get panelindex */
    const panelindex = $('fieldset.card-panel').index($collectionHolder.closest('.card-panel'));
    /* add data-parsley-group="block-$panelindex" to input fields */
    const newFormDOM = $(newForm);
    newFormDOM.find('input').attr('data-parsley-group','block-'+panelindex);
    witnessFormContainer.data('index', index + 1);
    const $newFormLi = $('<li></li>').append(newFormDOM);
    $newLinkLi.before($newFormLi);

    const witnessContainer = $('.witnessContainer');
    if (witnessContainer.length > 1) {
        addQuestionsFormDeleteLink($newFormLi);
    } else if (witnessContainer.length === 1) {
        $newFormLi.append('<div style="padding:16px; clear: both"></div>');
    }
}

function addQuestionsFormDeleteLink($tagFormLi) {
    const $removeFormA = $('<a href="#" class="red-text left">' +
        '<i class="fa fa-trash-o right"></i></a>');
    $tagFormLi.append($removeFormA);
    $removeFormA.on('click', function(e) {
        e.preventDefault();
        $tagFormLi.remove();
    });
}

function setWitnessFieldsRequired(requiredWitnessFields, required) {
    if (requiredWitnessFields) {
        requiredWitnessFields.each(function () {
            $(this).prop("required", required);
        });
    }
}