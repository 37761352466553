$(".datepicker").on("click",function(){
    var dateString = $(this).val();
    var parts = dateString.split("-");
    var date = new Date(parts[2],parts[1] -1, parts[0]);
    var options ={
        autoClose : true,
        defaultDate : date,
        format:'dd-mm-yyyy'
    };
    var instance = M.Datepicker.init(this, options);
    instance.setDate(date);
    instance.open();
});

const processingButtons = [];

(function($){
    $(document).ready(function(){

        // JS Script to detect if conduct type is selected or Not. Set Manage Wa;k In Report to Black if not found

        $('input[name=\'client_user[conductType][]\']').change(function() {
            if($(this).is(':checked')) {
                $("#client_user_walkInOnly").prop("checked", false);
            }else {
                var isChecked = false;
                $.each($("input[name=\'client_user[conductType][]\']:checked"), function () {
                    isChecked = true;
                });

                if(isChecked === false){
                    $("#client_user_walkInOnly").prop("checked", true);
                }
            }
        });


        if( $.each($("input[name=\'client_user[conductType][]\']:checked"), function () {
            $("#client_user_walkInOnly").prop("checked", false);
        }))


        $('#client_user_walkInOnly').change(function() {
            if(this.checked) {
                $('input[name=\'client_user[conductType][]\']').prop( "checked", false );
                $("#select_all_conductType").prop("checked", false);
            }
        });



        $.fn.dataTable.moment( 'DD-MM-YYYY hh:mm A' );

        $('.dropdown-button').dropdown();

        $('.largeNumberSwitcher').on('change', function(){
            var newNumber = $(this).val();
            console.log(newNumber + 'change');
            newNumber = parseInt(newNumber);
            $(this).parent().parent().siblings('.largeNumber').text(newNumber);
        });

        $('.graphSwitcher').on('change', function(){
            var newNumber = $(this).val();
            $(this).parent().parent().siblings('.graphs').addClass('hiddendiv');
            $(this).parent().parent().siblings('.' + newNumber).removeClass('hiddendiv');
        });

        $('.c__alert .c__close').click(function(e){
            e.preventDefault();
            $(this).parent().addClass('c__hide');
            setTimeout(function(){
                $('.c__alert.c__hide').addClass('c__hidden');
            }, 250);
        });


        $('select:not(.metaTags):not(#organisationList):not(#authy-countries)').formSelect({

        });

        $('#authy-countries').select2();
        function getDefaultSort(thisCol){
            var index = ($(thisCol).find('th.defaultSort').index()>-1) ? $(thisCol).find('th.defaultSort').index() :0;
            return index;

        }

        $('.collapsible').collapsible();

        $(".metaTags").select2({
            tags: true,
            tokenSeparators: [',']
        });

        $('.c__table').not('.no__dataTables').each(function(){
            if ( ($(this).find("tbody td").length > 0) && ($(this).find("thead tr").length > 0) ) {
                $(this).DataTable({
                    'paging': true,
                    'searching': false,
                    'info': false,
                    'dom': 't',
                    "order": [[getDefaultSort($(this)), "desc"]]
                });
            }
        });


        $('.c__search-trigger').on('click', function(e){
            e.preventDefault();
            $('.c__search-form').addClass('active');
        });


        $('.modal').modal();

        $('.timepicker').timepicker();

        $('body').delegate('.btnCheckbox', 'click', function () {
            var checkboxElement = $(this);
            var idTask = checkboxElement.data('task');
            var is_done = (this.checked === true) ? 1: 0;
            var data = {
                'disclosureTask' : idTask,
                'is_done' : is_done
            };
            var routeToPost = checkboxElement.data('url');

            $.post( routeToPost, data, function( response ) {
                PopupNotification(response.responseMessage, response.popupType);
            });

        }).delegate('.removeTask', 'click', function (eventObject) {
            eventObject.preventDefault();
            var currentButton = $(this);
            getURL = currentButton.attr('href');

            $.get( getURL, function( data ) {
                reDrawTaskContainer();
                PopupNotification('Task Removed');
            })
                .fail(function( data ) {
                    PopupNotification(data.msg, 'formError');
                });


        });
        // post form
        $(".ajaxSubmitBtn").click(function (eventObject) {
            eventObject.preventDefault();
            var currentButton =  $(this);
            var orginalLabelValue = currentButton.text();
            currentButton.addClass('disabled');
            processingButtons.push({ buttonObj: currentButton, originalText: currentButton.text()});
            currentButton.text('processing...');
            currentButton.attr('disabled', true);
            $('.taskError').removeClass('active');
            var postURL = $(this).data('url');
            var formID = $(this).data('formid');
            if (!postURL) {
                PopupNotification('No Post URL', 'websiteError');
            }
            var formData = $('#'+formID).serializeArray();

            $.post( postURL, formData, function( data ) {
                reDrawTaskContainer();
                currentButton.removeClass('disabled');
                currentButton.attr('disabled', false);
                currentButton.text(orginalLabelValue);
                $('#'+formID)[0].reset();
                PopupNotification(data.msg);
            })
                .fail(function( data ) {
                    PopupNotification('Failure', 'formError');
                    console.log(data);
                })
            ;
            return false;
        });

        $(".ajaxSubmitMetaBtn").click(function (eventObject) {
            var currentButton =  $(this);
            currentButton.attr('disabled', true);
            var orginalLabelValue = $(this).attr('value');
            currentButton.attr('value', 'Submitting...');
            $('.taskError').removeClass('active');
            var postURL = $(this).data('url');
            var formID = $(this).data('formid');
            if (!postURL) {
                PopupNotification('No Post URL', 'websiteError');
            }
            var formData = $('#'+formID).serializeArray();

            $.post( postURL, formData, function( data ) {
                currentButton.attr('disabled', false);
                currentButton.attr('value', orginalLabelValue);
                PopupNotification(data.msg);
            })
                .fail(function( data ) {
                    PopupNotification('Failure', 'formError');
                    console.log(data);
                })
            ;
            return false;
        });

        $('#loginAuthForm').on('click', '.setupVerify', function () {
            $(this).append($('<input type="hidden" name="setupVerify" value="true" />'));
        });

        function reDrawTaskContainer(url) {
            var taskContainer = $('#taskListContainer');
            if(typeof url === 'undefined' || !url) {
                url = taskContainer.data('url');
            }
            $.get(url, function (response){
                taskContainer.html(response.data);
            })
        }


        $.fn.equalHeight = function() {
            var maxHeight = 0;
            return this.each(function(index, box) {
                var boxHeight = $(box).height();
                maxHeight = Math.max(maxHeight, boxHeight);
            }).height(maxHeight);
        };

        $(document).ready(function() {
            $('.equal-height-icons').equalHeight();
            if($(window).width() > 767) {
                $('.equal-height').equalHeight();
                $('.equal-height-sol').equalHeight();
                $('.equal-height-foot').equalHeight();
                $('.equal-height-foot').equalHeight();
            } else if($(window).width() <= 767) {
                $('.equal-height').css('height','auto');
                $('.equal-height-sol').css('height','auto');
                $('.equal-height-foot').css('height','auto');
                $('.equal-height-icons').equalHeight();
            }
        });

        $(window).resize(function(){
            $('.equal-height').css('height','auto');
            $('.equal-height').equalHeight();
            // if($(window).width() >)
            $('.equal-height-sol').css('height','auto');
            $('.equal-height-sol').equalHeight();
            $('.equal-height-foot').css('height','auto');
            $('.equal-height-foot').equalHeight();

            if($(window).width() <= 767) {
                $('.equal-height-icons').css('height','auto');
                $('.equal-height-icons').equalHeight();
            }

        });


        if($('.cardValidationMessage').length > 0) {
            setTimeout(function(){
                $('.cardValidationMessage').css({'max-height':'0','opacity':'0'});
            }, 3000);
        }


        $('.disabled').mouseenter(function(){
            $('.disabledMessage').addClass('active')
        });
        $('.disabled').mouseleave(function(){
            $('.disabledMessage').removeClass('active')
        });

        // $('.statusSwitcher').appendTo('.statusContainer');





        $(function() {

            if($('#dashNav').length > 0) {
                if($(window).width() > 769) {
                    window_top = $(window).scrollTop();
                    div_top = $('#dashNav').offset().top;
                    $(window).scroll(function(){
                        window_top = $(window).scrollTop();

                        if (window_top > div_top) {
                            $('#dashNav').addClass('stick');
                            $('body').addClass('fixedNavActive');
                        } else {
                            $('#dashNav').removeClass('stick');
                            $('body').removeClass('fixedNavActive');
                        }
                    });
                }
            }
        });
        /** Only include any form that is generic
         * Customized forms need to be excluded from this array.
         */
        var parsleyForms = [
            'organization',
            'disclosure_analysis',
            'disclosure_note_activity',
            'disclosure_save',
            'disclosure_client_comment',
            'disclosure_log_event_activity',
            'cm_disclosure_form',
            'contact_report'
        ];

        parsleyForms.forEach(function (formName) {
            var form = $('form[name="' + formName + '"]');
            if (form.length > 0) {
                form.parsley({
                    errorClass: function(){
                        //$(this).parent().removeClass('has-success').addClass('has-error');
                    },
                    successClass: function(){
                        //$(this).parent().removeClass('has-error').addClass('has-success');
                    },
                });
                form.find('button[type=submit]').on('click', function(e) {
                    if (! form.parsley().validate()) {
                        return false;
                    }
                });
            }
        });

        if($('form[name="walk_in_report"]').length > 0) {
            $('form[name="walk_in_report"]').parsley({
                errorClass: function(){
                    $(this).parent().removeClass('has-success').addClass('has-error');
                },
                successClass: function(){
                    $(this).parent().removeClass('has-error').addClass('has-success');
                },
            });
            $('form[name="walk_in_report"]').removeAttr('novalidate');

            $('.parsleyValTrigger').on('click', function(e){
                e.preventDefault();
                var validation = $(this).closest('form[name="walk_in_report"]').parsley();
                if (validation.validate() && validation.isValid()) {
                    $('#walkInSubmit').removeClass('inactive');
                    $('#walkInSubmit').modal('open');
                }
            });
        }

        $('.walk_in_task_list_item .edit_link').click(function(e) {
            e.stopPropagation();
            M.Collapsible.getInstance($(this).parents('.collapsible')).open(e.target.dataset.edit);
        });

        if($('form[name="disclosure_message"]').length > 0) {
            $('form[name="disclosure_message"]').parsley({
                errorClass: function(){
                    $(this).parent().removeClass('has-success').addClass('has-error');
                },
                successClass: function(){
                    $(this).parent().removeClass('has-error').addClass('has-success');
                },
            });
            $('form[name="disclosure_message"]').removeAttr('novalidate');

            $('.parsleyValTrigger').click(function(e){
                var validation = $(this).closest('form[name="disclosure_message"]').parsley();
                if (validation.validate() && validation.isValid()) {

                    $('#submitModal').removeClass('inactive');
                    $('#submitModal').modal('open');

                } else {
                    $('#submitModal').addClass('inactive');
                }

                $('.parsley-errors-list').each(function(){
                    if($(this).parent().hasClass('messageSubject')) {
                        $(this).insertAfter($(this).closest('.messageSubject'));
                    }
                });



            });

        }


        if(($('form[name="disclosure_message"]').length < 1) || ($('form[name="walk_in_report"]').length < 1)) {
            //** Not sure That this required - need to test walk_in_report first
            // $('.shareModal').modal('open');
            // $('.shareModal').modal({
            //     dismissible: true,
            // });
        }

        $('.notifications.triggerActive').click(function(e){
            e.preventDefault();
            $('.notificationList').toggleClass('active');
        });










        $('.searchTrigger').click(function(e){
            e.preventDefault();
            $(this).parent().siblings('.searchBoxContainer').children('.searchBoxForm').toggleClass('active')
        });
        $('.c__advanced-trigger').click(function(e){
            e.preventDefault();
            $('.c__advanced-search--container').toggleClass('active')
        });

        $('.advancedSearchClose').click(function(e){
            e.preventDefault();
            $('.c__advanced-search--container').removeClass('active')
        });

    });


    $('.c__notifications').parent().on('mouseenter', function(){

        var totalHeight = 0;
        if($('.c__notification-list').length > 0) {
            $(".c__notification-list").children().each(function(){
                totalHeight = totalHeight + $(this).outerHeight(true);
            });
            $('.c__notification-list').addClass('active');
            $('.c__notification-list').css('max-height', totalHeight);
        }

    });

    $('.c__notifications').parent().on('mouseleave', function(){


        if($('.c__notification-list').length > 0) {

            $('.c__notification-list').removeClass('active');
            $('.c__notification-list').css('max-height', 0);
        }

    });

    $('form button[type=submit]').click(function(event) {
        var button = $(event.target);
        var form = $(event.target.form);
        if (button.hasClass('no-proc')) {
            return;
        }
        form.find('button[type=submit]').each(function() {
            $(this).addClass('disabled');
        });

        processingButtons.push({ buttonObj: button, originalText: button.text()});
        button.text('processing');
    });

    $('#resetPassword').click(function () {
        var url = $(this).data('updateurl');
        var currentPassword = $('#currentDINPassword');
        $.get(url)
            .done(function (response) {
                currentPassword.text(response);
            })
            .fail(function (response) {
                alert('Failed Updating the password: '+response)
            })
    });

    $('#deleteDINFile').click(function (e) {
        e.preventDefault();
        var url = $(this).data('deleteurl');
        var deleteLinkHolder = $(this);
        var fileLabel = $('#DINFileLabel');
        if (isEmpty(url)) {
            return;
        }
        $.get(url)
            .done(function (response) {
                Materialize.toast('File has been removed successfully!', 4000);
                fileLabel.hide();
                deleteLinkHolder.hide();
            })
            .fail(function (response) {
                alert('Failed removing the file: '+response.msg)
            })
        return;
    });

    $('.delete-task-button').click(function (e) {
        e.preventDefault();
        var url = $(this).data('deleteurl');
        var modalId = $(this).data('modal-id');
        var taskRowId = $(this).data('task-header-id');
        if (isEmpty(url)) {
            return;
        }
        $(this).attr('disabled', true);
        processingButtons.push({ buttonObj: this, originalText: this.text()});
        $(this).text('processing');

        $.get(url)
            .done(function(response) {
                $(modalId + ' .task-delete-confirm').addClass('hide');
                $(modalId + ' .task-delete-complete').removeClass('hide');
                $(modalId + ' .task-delete-complete .success').removeClass('hide');
                $(taskRowId).addClass('hide'); // Removing breaks accordion.
            })
            .fail(function (response) {
                console.log(response);
                $(modalId + ' .task-delete-confirm').addClass('hide');
                $(modalId + ' .task-delete-complete').removeClass('hide');
                $(modalId + ' .task-delete-complete .error').removeClass('hide');
            });
    });

    function isEmpty(str) {
        return (!str || 0 === str.length);
    }



})(jQuery); // end of jQuery name space

/* add required-if validator to parsley */
window.Parsley.addValidator("requiredIf", {
    validateString : function(value, requirement) {
        if(jQuery(requirement).is('[type=radio],[type=checkbox]') && !jQuery(requirement).is(':checked')){
            return true;
        }
        if (jQuery(requirement).val()){
            return !!value;
        }
        return true;
    },
    priority: 33
});

window.Parsley.addValidator("requiredOneContact", {
    validateString : function(value, requirement) {
        if(jQuery(requirement).is('[type=radio],[type=checkbox]') && !jQuery(requirement).is(':checked')) {
            return true;
        }
        return !(jQuery('input[name="disclosure_identity[mobile_number]"]').val().trim() === ''
            && jQuery('input[name="disclosure_identity[email]"]').val().trim() === '');
    },
    messages: {
        en: 'Must provide at least one contact method (email or mobile)'
    },
    priority: 34
});
/* debug validation */
window.Parsley.on('field:error', function() {
    // This global callback will be called for any field that fails validation.
    //console.log('Validation failed for: ', this.$element);
    //console.log($(this.$element));
    $(this.$element).parent().removeClass('has-success').addClass('has-error');
});
window.Parsley.on('field:success', function() {
    $(this.$element).parent().removeClass('has-error').addClass('has-success');
});
window.Parsley.on('form:error', function() {
    while ( processingButtons.length > 0 ) {
        const button = processingButtons.pop();
        button.buttonObj.text(button.originalText);
        button.buttonObj.removeClass('disabled');
    }
});
