var notificationTypes = {
    success: '<div class="card cardValidationMessage">'
    + '<div class="card-content green-text">'
    + '<span class="card-title">Success!</span>'
    + '<p>__message__</p>'
    + '</div></div>'
    ,
    websiteError: '<div class="card cardValidationMessage">'
    + '<div class="card-content red-text">'
    + '<span class="card-title">Error:</span>'
    + '<p>__message__</p>'
    + '</div></div>'
    ,
    formError: '<div class="card cardValidationMessage">'
    + '<div class="card-content red-text">'
    + '<span class="card-title">Error:! Please fix the below question response</span>'
    + '<p>__message__</p>'
    + '</div></div>'
};

function PopupNotification(msg, popupType, delay, attachTo) {
    if (typeof popupType === 'undefined') {
        popupType = 'success'
    }
    if (typeof attachTo === 'undefined') {
        attachTo = '.dashMain';
    }
    if (typeof delay === 'undefined') {
        delay = 2000;
    }
    var dashMain = $(attachTo);

    var flashCard = notificationTypes[popupType];
    flashCard = flashCard.replace('__message__', msg);

    dashMain.prepend(flashCard).children(':first')
        .delay(delay)
        .fadeOut(5000);
}
