var ycadmin = {
  refreshSessionUrl:'',
  forceLogoutUrl:'',
  ajaxRefresh: '',
  activeSessionTimer:'',
  serverSessionTimer: '',
  memoryStorage: {},
  maxInactiveSeconds: 0,
  secondsToShowInactiveWarning: 120,
  _activeCheckinterval: 5,
  _serverSessionRefreshInterval: 300, 

  pathsToIgnore: [
      "/",
      "/login",
      "/app.php",
      "/app.php/",
      "/app_dev.php",
      "/app_dev.php/",
      "/app.php/login",
      "/app_dev.php/login"
  ],

  showInactivityAlert : function (remainTime)
  {
    var remainMin = Math.round(remainTime / 60);
    if ( remainMin > 0 ) {
      $('#remain-minute').html(remainMin + " minutes");
    } else {
      $('#remain-minute').html(remainTime + " seconds");
    }

    $('#inactivityModal').modal('open');
  },

  closeInactivityAlert : function ()
  {
    $('#inactivityModal').modal('close');
  },

  refreshServerSession : function ()
  {
    this.consoleDebug("refreshServerSession")

    if (typeof this.ajaxRefresh == 'object') {
      this.consoleDebug("refreshServerSession aborting", {"this.ajaxRefresh": this.ajaxRefresh})
      this.ajaxRefresh.abort();
    }

    this.consoleDebug("refreshServerSession ajax requesting this.refreshSessionUrl " + this.refreshSessionUrl);
    this.ajaxRefresh = $.ajax({
      type: "GET",
      url:  this.refreshSessionUrl,
      success: function () {
        ycadmin.consoleDebug("refreshServerSession success");
      },
      error: function (msg) {
        ycadmin.consoleDebug("refreshServerSession error msg", msg);
      },
    });
  },

  checkActiveSession : function ()
  {
    this.consoleDebug("checkActiveSession");

    if (this.hasAlreadyLoggedOut()) {
      this.consoleDebug("checkActiveSession: hasAlreadyLoggedOut()");
      this.logout(true);
    } else if (this.isTooLongSinceActive()) {
      this.consoleDebug("checkActiveSession: isTooLongSinceActive()");
      this.logout(false);
      // Problem: The session has been logged out of this tab, but what about the other tabs
      // they may stay because the activity timeout will reset when *this* tab redirects to logout
    } else if (this.isInactiveWarningEnabled()) {
      this.consoleDebug("checkActiveSession: isInactiveWarningEnabled()");
      this.showInactivityAlert(this.getInactiveSecondsLeft());
    } else {
      this.consoleDebug("checkActiveSession: inactivityModal should be closed (not visible)");
      this.closeInactivityAlert();
    }
  },

  logout : function (has_already_logged_out)
  {
    if (! has_already_logged_out) {
      // Don't cause race condition if logging out because already logged out elsewhere
      this.storageSet("time_last_logged_out", Date.now());
    }

    // Give the logout time to complete, don't remove altogether in case the user cancels the logout using 'ESC'
    this._activeCheckinterval = 60;
    this.consoleDebug("_activeCheckinterval set to " + this._activeCheckinterval);
    this._serverSessionRefreshInterval = 120;
    this.consoleDebug("_serverSessionRefreshInterval set to " + this._serverSessionRefreshInterval);

    // Re-set the intervals now
    clearInterval(this.activeSessionTimer);
    this.activeSessionTimer = setInterval(function () { ycadmin.checkActiveSession();   }, this._activeCheckinterval*1000);
    clearInterval(this.serverSessionTimer);
    this.serverSessionTimer = setInterval(function () { ycadmin.refreshServerSession(); }, this._serverSessionRefreshInterval*1000);

    window.location.href = this.forceLogoutUrl;
  },

  hasAlreadyLoggedOut: function ()
  {
    this.consoleDebug("getTimeLastActive")
    return (this.storageGet("time_last_logged_out") != this.timeLastLoggedOut);
  },

  getTimeLastActive : function ()
  {
    this.consoleDebug("getTimeLastActive")
    return this.storageGet("time_last_active");
  },

  resetTimeLastActive : function ()
  {
    this.consoleDebug("resetTimeLastActive")
    this.storageSet("time_last_active", Date.now());
  },

  getSecondsSinceLastActive: function ()
  {
    this.consoleDebug("getSecondsSinceLastActive");
    var seconds_since_last_active = Math.floor((Date.now() - this.getTimeLastActive()) / 1000);
    this.consoleDebug("getSecondsSinceLastActive = " + seconds_since_last_active);
    return seconds_since_last_active;
  },

  getInactiveSecondsLeft: function ()
  {
    this.consoleDebug("getInactiveSecondsLeft");
    return (this.maxInactiveSeconds - this.getSecondsSinceLastActive());
  },

  isInactiveWarningEnabled: function ()
  {
    this.consoleDebug("isInactiveWarningEnabled");
    return (this.getInactiveSecondsLeft() <= this.secondsToShowInactiveWarning);
  },

  isTooLongSinceActive: function ()
  {
    this.consoleDebug("isTooLongSinceActive");
    return this.getSecondsSinceLastActive() > this.maxInactiveSeconds;
  },

  storageGet: function (key)
  {
    if (this.useLocalStorage) {
      this.consoleDebug("storageGet - using localStorage " + key);
      return localStorage.getItem(key);
    } else {
      this.consoleDebug("storageGet - using memoryStorage " + key);
      return this.memoryStorage[key];
    }
  },

  storageSet: function (key, value)
  {
    if (this.useLocalStorage) {
      this.consoleDebug("storageSet - using localStorage " + key + " -> " + value);
      localStorage.setItem(key, value);
    } else {
      this.consoleDebug("storageSet - using memoryStorage " + key + " -> " + value);
      this.memoryStorage[key] = value;
    } 
  },

  consoleDebug: function (message)
  {
    if (this.debug) {
      console.debug("ycadmin: " + message);
    }
  },

  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
  isStorageAvailable : function (type)
  {
    var storage;
    try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch(e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
    }
  },

  init : function (configured_session_time, refreshSessionUrl, forceLogoutUrl)
  {
    this.debug = false; // Make this true to debug

    // We don't want the inactivity functionality on certain pages in order to
    // avoid ugly redirect loops.  When redirecting to a page that redirects you
    // back where you were, the page doesn't actually reload, so the inactivity
    // modal will stay visible with a "0 seconds" counter without this check
    var numPathsToIgnore = this.pathsToIgnore.length;
    for (var i = 0; i < numPathsToIgnore; i++) {
      if (location.pathname == this.pathsToIgnore[i]) {
        this.consoleDebug("Ignore path matched '" + location.pathname + "', no inactivity timeout on this page");
        return;
      }
    }

    // Maintain backwards compatibility
    this.useLocalStorage = this.isStorageAvailable('localStorage');

    // Set up config from the modal
    this.refreshSessionUrl = refreshSessionUrl;
    this.forceLogoutUrl = forceLogoutUrl;
    this.maxInactiveSeconds = configured_session_time;

    // Keep track of this in case another tab/window logs out
    this.timeLastLoggedOut = this.storageGet("time_last_logged_out");

    // Start active timer from now (page load) 
    this.resetTimeLastActive();

    // Set up the tasks on a timer 
    this.activeSessionTimer = setInterval(function () { ycadmin.checkActiveSession();   }, this._activeCheckinterval*1000);
    this.serverSessionTimer = setInterval(function () { ycadmin.refreshServerSession(); }, this._serverSessionRefreshInterval*1000);

    $(document).bind('keypress click', null, function () {
      ycadmin.checkActiveSession();  // May have expired/logged out before the activity 
      ycadmin.resetTimeLastActive();
      ycadmin.closeInactivityAlert();
    });

  }
};

$(document).ready(function () {
  ycadmin.consoleDebug("document.ready");
  var modal = $('#inactivityModal');
  modal.modal(); //init materialize modal
  var session_time = modal.data('session-length');
  var refresh_url = modal.data('homepage-url');
  var logout_url = modal.data('logout-url');
  ycadmin.consoleDebug("ycadmin.js", {"session_time": session_time, "refresh_url": refresh_url, "logout_url": logout_url});
  ycadmin.init(session_time, refresh_url, logout_url);
});
